













































import { MetaInfo } from 'vue-meta'
import { Component } from 'vue-property-decorator'
import qs from 'qs'

// components
import CourseStoreCard from '@/components/cards/CourseStoreCard.vue'
import OrderPaymentFormNew from '@/components/forms/order/OrderPaymentFormNew.vue'
import SystemMessageCard from '@/components/cards/SystemMessageCard.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import MasterCoursesModule from '@/store/modules/master/courses'
import MasterEducationModule from '@/store/modules/master/education'
import MasterOrdersModule from '@/store/modules/master/orders'
import { MasterRecommendationResource, NameValueResource, OrderStatus } from '@/store/types'

@Component({
  components: {
    CourseStoreCard,
    OrderPaymentFormNew,
    SystemMessageCard,
  },
})
export default class Order extends NotifyMixin {
  private isConfirmed = false
  private orderStatus: OrderStatus | null = null
  private isLoading = true
  private isRecurrent = false

  private get orderUUID () {
    return this.$route.params.orderUUID
  }

  private get self () {
    return AuthModule.self
  }

  private get paymentErrors() {
    return DictionaryModule.paymentErrors
  }

  private localPaymentErrors: { [key: string]: string } = {}

  private queryParams: any = {}

  private recommendedCourses: MasterRecommendationResource[] = []

  // После продления курса нужно запросить мастер-гурппы, если заказ бесплатный.
  // Нужно, чтобы туториал знал про обновление мастер-групп
  private isGetMasterGroup = false

  private beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      if (from.name === 'redirect.payment') {
        vm.queryParams = from.query
      }

      if (from.name === 'master' || from.name === 'master.store') {
        vm.isGetMasterGroup = true
      }
    })
  }

  private showNotifications(messages: string[]) {
    messages.forEach(message => this.notifyError(this.localPaymentErrors[message] || 'Что-то пошло не так, повторите попытку позже.'))
  }

  private mounted() {
    this.paymentErrors.map(item => this.localPaymentErrors[item.value] = item.name)
    if (!this.orderUUID) {
      this.$router.replace({ name: this.self ? 'master.courses.my' : 'auth.login' })
      this.notifyError('Некорректная ссылка')
    } else {
      if (this.self) {
        this.getPaymentStatus()
      } else {
        AuthModule.fetchUser()
          .finally(this.getPaymentStatus)
      }
      // Показываем ошибки из урла, если есть
      if (Object.keys(this.queryParams).length) {
        if (Object.keys(this.localPaymentErrors).length) {
          const { messages } = qs.parse(qs.stringify(this.queryParams)) as { [key: string]: string[] }
          if (messages) {
            this.showNotifications(messages)
          }
        } else {
          this.$bus.$on('getPaymentErrors', this.getPaymentErrors)
        }
      }
    }
  }

  private destroyed() {
    this.$bus.$off('getPaymentErrors', this.getPaymentErrors as any)
  }

  private getPaymentErrors(errors: NameValueResource[]) {
    errors.map(item => this.localPaymentErrors[item.value] = item.name)
    const { messages } = qs.parse(qs.stringify(this.queryParams)) as { [key: string]: string[] }
    if (messages) {
      this.showNotifications(messages)
    }
  }

  private getPaymentStatus() {
    MasterOrdersModule.getPaymentStatus(this.orderUUID)
      .then(response => {
        this.orderStatus = response.status
        this.isRecurrent = response.isInitRecurringPayment
        if (this.orderStatus === OrderStatus.WAIT) {
          this.fetchPayment()
        } else if (this.self && this.orderStatus === OrderStatus.PAID) {
          this.fetchRecommendationCourses(response.courseId)
        } else {
          if (this.orderStatus === OrderStatus.PAID && this.isGetMasterGroup) {
            MasterEducationModule.fetchMasterGroups()
          }
          this.isLoading = false
        }
      })
      .catch(() => {
        this.orderStatus = null
        this.isLoading = false
      })
  }

  private fetchRecommendationCourses(courseID: number) {
    MasterCoursesModule.fetchRecommendations(courseID)
      .then(response => {
        this.recommendedCourses = response
        localStorage.removeItem('lastOrderCourseID')
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  private fetchPayment() {
    MasterOrdersModule.fetchPayment(this.orderUUID)
      .then(() => {
        this.isConfirmed = true
        this.isLoading = false
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Оформление заказа',
    }
  }
}
